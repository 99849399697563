<template>
  <div v-if="products.length > 0" class="mb-5" style="">

    <div class="main-bg-img dessus"></div>

    <v-row>
      <v-col cols="10" sm="10" class="mx-auto">
        <h1 class="d-none">Galerie</h1>
        <div class="font-flower" style="font-size:70px;"><span style="font-size:110px;">G</span>alerie</div>
        <v-col cols="12" md="8" lg="7" class="pt-0">
          <!-- <p>Retrouvez l'ensemble de mes réalisations, depuis mes débuts...
            <!- - <br>
            Si vous souhaitez commander votre propre mandala personnalisé, je vous invite à faire un tour dans 
            <router-link to="/commande">l'atelier</router-link> 
            pour découvrir les choix disponibles... - ->
            <br>
          </p> -->
        </v-col>
        <br>
        <v-row>
          <template v-for="(product) in products">
            <v-col cols="12" sm="6" md="4" lg="4" 
                  class="pa-5 mt-5 text-center" 
                  v-if="product.categories.length > 0 && product.categories[0].name == 'Mandala original'"
                  :key="product.name">
              <v-img   class="mx-auto" 
                      :class="product.isCircle ? 'img-rounded' : 'img-squarded'"
                      @click="openDialogProduct(product)"
                      :aspect-ratio="1/1"
                      :src="baseUrl() + product.image"></v-img>
                      <span class="font-dancing" style="font-size:25px;">{{ product.name }}</span>
                      <!-- <br><small>{{ product.description.substr(0, 50) }}...</small> -->
            </v-col>
          </template>
        </v-row>
        
      </v-col>
    </v-row>

    <v-dialog v-model="showProductDialog" v-if="productToShow != null"  
              :fullscreen="$vuetify.breakpoint.width < 700"
              transition="dialog-bottom-transition" max-width="600" scrollable>
      <DialogProduct :product="productToShow"></DialogProduct>
    </v-dialog>

  </div>
</template>


<script>
    import core from '../plugins/core.js'

    import DialogProduct from '@/components/main-layout/DialogProduct.vue'
    
    //import { i18n } from 'vue-lang-router';

    export default {
        name: "App",
        components: {
          DialogProduct
        },
        data: function () {
            return {
                products: [],
                productToShow: false,
                showProductDialog: false,
                flip: false
            };
        },
        mounted: function(){
          this.initData()
          this.$root.$on('closeDialogProduct', () => { this.closeDialogProduct() })
          this.$store.dispatch('app/incStat', '/gallery')
        },
        methods: {
            
            async initData(){
              await this.$store.dispatch('app/fetchEntities', 
                                { entityType: "product", 
                                  query: { public: true }, 
                                  sort: { 'created' : -1 } 
                                })
              this.products = this.$store.state.app.data.product
              console.log("products", this.products)
            },
            openDialogProduct(product){
              this.productToShow = product
              this.showProductDialog = true
            },
            closeDialogProduct(){
              this.productToShow = null
              this.showProductDialog = false
            },

            baseUrl(){ return core.baseUrl() }
        },
        computed:{
        }
    };
</script>

